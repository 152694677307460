import { useCallback, useEffect, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";

import { Box, Grid, MenuItem, Paper, Select, TextField, Tooltip, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/material";

import Post from "./Post";
import NoPosts from "./NoPosts";
import UpgradeModal from "./UpgradeModal";

import Pagination from "../../ReusableComponents/Pagination";
import StyledButton from "../../ReusableComponents/Button";
import Modal from "../../ReusableComponents/Modal";

import { MdOutlineUpdate } from "react-icons/md";
import PostSkeleton from "./PostSkeleton";
import "react-loading-skeleton/dist/skeleton.css";
import PourcentageCard from "./PourcentageCard";
import NoPostsFoundDialog from "./NoPostsFoundDialog";
import { useNurtureContext } from "../../../../Context/NurtureContext";
import CookieErrDialog from "./CookieErrDialog";
// import { deletePosts } from "../../../../Services/GetProspectsPosts";

const styles = {
  Paper: {
    boxShadow: "none",
    border: "none",
    width: "100%",
    backgroundColor: "var(--color-grey-50)",
    position: "relative",
  },
  FilterBox: {
    marginBottom: "2rem",
  },
  FilterText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#2F3EAC",
  },
  FilterSelect: {
    fontFamily: "Inter",
    backgroundColor: "#F0F1F3",
    borderRadius: "100px",
    padding: "4px 15px !important",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#7C8B9D",
    "& .MuiSelect-select:focus": {
      background: "none",
    },
    "& svg": {
      height: "24px",
      width: "24px",
      color: "#7C8B9D99",
      marginRight: "4px",
    },
  },
  FilterBtn: {
    fontFamily: "Inter",
    display: "flex",
    alignItems: "center",
    background: "none",
    gap: "0.5rem",
    textTransform: "none",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    borderRadius: "100px",
    padding: "4px 10px !important",
  },
  FilterBtnActif: {
    color: "#2f3eac",
  },
  FilterBtnInactif: {
    color: "#7C8B9D",
  },
  FilterInputs: {
    backgroundColor: "#F0F1F3",
    borderRadius: "100px",
    padding: "4px 15px",
    fontSize: "12px",
    fontWeight: "600",
    color: "#7C8B9D99",
    "& svg": {
      height: "24px",
      width: "24px",
      color: "#7C8B9D99",
      marginRight: "4px",
    },
  },
  ProspectInput: {
    maxWidth: "160px",
    "&::placeholder": {
      fontSize: "12px",
      fontWeight: "600",
      color: "#7C8B9D99",
      padding: "4px 15px",
    },
  },
  ListFilter: {
    "& .MuiSelect-select:focus": {
      background: "none",
    },
  },
};

function Index() {
  const {
    onMessage,
    sendMessage,
    isUpdating,
    setIsUpdating,
    updateFinished,
    setUpdateFinished,
    postsFound,
    setPostsFound,
    errorUpdaing,
    setErrorUpdating,
    openNoPostsFound,
    setOpenNoPostsFound,
    getInitialState,
    allPosts,
    setRefreshPostsKey,
    orderBy,
    setOrderBy,
    isLoadingPosts: isLoading,
    showCookieErr,
    setShowCookieErr,
    lists,
    prospects: prospectsData,
  } = useNurtureContext();
  const { premiumUser, userId } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();

  // ------------------------------------------------------------------------------------------------
  // STATE
  // ------------------------------------------------------------------------------------------------
  const [filterBy, setFilterBy] = useState("choose a filter");
  const [prospectNameFilter, setProspectNameFilter] = useState("");
  const [listFilter, setListFilter] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [currentPagePosts, setCurrentPagePosts] = useState([]);
  const [resultsPerPage, setResultsPerPage] = useState(12);
  const currentPage = !searchParams.get("page") ? 1 : parseInt(searchParams.get("page"));

  const handleOrderChange = (event) => {
    setOrderBy(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterBy(event.target.value);
  };
  const filterTable = useCallback(() => {
    if (!allPosts && !postsFound) return;

    let draft = [...postsFound, ...allPosts];
    if (filterBy !== "choose a filter") {
      if (filterBy === "viewed") {
        draft = draft.filter((post) => post["is complete"]);
      }
      if (filterBy === "notViewed") {
        draft = draft.filter((post) => !post["is complete"]);
      }
      if (filterBy === "list" && listFilter && listFilter !== 0) {
        draft = draft.filter((post) => {
          return post["list title"] === listFilter;
        });
      }
      if (filterBy === "prospectName") {
        if (!prospectNameFilter) return;
        draft = draft.filter((post) => {
          const prospectName = `${post.first_name} ${post.last_name}`.trim();
          return prospectName.toLowerCase().includes(prospectNameFilter.toLowerCase());
        });
      }
      if (filterBy === "reposted") {
        draft = draft.filter((post) => post.is_repost);
      }
      if (filterBy === "reshared") {
        draft = draft.filter((post) => post.is_reshare);
      }
    }

    setFilteredPosts(draft);
  }, [filterBy, prospectNameFilter, listFilter, allPosts, postsFound]);

  useEffect(() => {
    filterTable();
  }, [filterTable, prospectNameFilter, listFilter, filterBy]);

  useEffect(() => {
    if (filterBy === "list" && listFilter === 0) {
      return;
    } else {
      searchParams.set("page", 1);
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBy, listFilter, resultsPerPage]);

  useEffect(() => {
    const from = (currentPage - 1) * resultsPerPage;
    const to = from + resultsPerPage - 1;
    setCurrentPagePosts((filteredPosts?.length > 0 ? filteredPosts : []).slice(from, to + 1));
  }, [filteredPosts, currentPage, resultsPerPage]);

  // -----------------------------------------------------------------------------------------------
  // SOCKETS PART
  // -----------------------------------------------------------------------------------------------
  async function sendMessageToProspect({ id, linkedinProfileUrl }) {
    const message = {
      action: "sendMessage",
      prospectId: id,
      userId,
      linkedinProfileUrl,
    };

    sendMessage(JSON.stringify(message));
    await waitForConfirmation(id);
  }

  async function waitForConfirmation(prospectId) {
    const messages = ["Post already exists", `Scraping for prospect ${prospectId} done.`];
    const errMessages = ["Missing cookie", "Invalid cookie"];
    return new Promise((resolve) => {
      onMessage((data) => {
        if (messages.includes(data.message)) {
          resolve();
        } else if (data.message === "Scraped data") {
          const { post_url, is_repost, is_reshare, postDate, postSummary, first_name, last_name } = data;
          const postData = {
            "post summary": postSummary,
            "post date": postDate,
            "is complete": false,
            "post link": post_url,
            first_name,
            last_name,
            is_repost,
            is_reshare,
          };
          setPostsFound((prev) => [...prev, postData]);
        } else if (errMessages.includes(data.message)) {
          setShowCookieErr(true);
        } else if (data.message === "Internal server error") {
          setErrorUpdating(true);
          resolve();
        }
      });
    });
  }

  const getUpdates = async () => {
    try {
      setIsUpdating(true);
      for (const prospect of prospectsData) {
        const data = {
          id: prospect.id,
          linkedinProfileUrl: prospect["LinkedIn profile"],
        };
        await sendMessageToProspect(data);
      }
      setUpdateFinished(true);
    } catch (err) {
      setErrorUpdating(true);
    }
  };

  useEffect(() => {
    if (updateFinished && postsFound.length === 0 && !showCookieErr && !errorUpdaing) {
      setOpenNoPostsFound(true);
    }
  }, [updateFinished, postsFound, showCookieErr, errorUpdaing, setOpenNoPostsFound]);

  // const handleDeletePosts = async () => {
  //   getInitialState();

  //   const ids = allPosts.map((post) => post.id);
  //   await deletePosts(ids);
  //   setRefreshPostsKey((oldKey) => oldKey + 1);
  // };

  return (
    <Paper sx={{ ...styles.Paper, position: "relative", minHeight: isLoading ? "100vh" : "auto" }}>
      <Modal>
        <Box sx={styles.FilterBox} display="flex" alignItems="center" gap="1rem">
          {allPosts && allPosts.length > 0 && (
            <>
              <Typography sx={styles.FilterText}>Order by:</Typography>
              <Select
                defaultValue={1}
                variant="standard"
                value={orderBy}
                onChange={handleOrderChange}
                sx={styles.FilterSelect}
                disableUnderline={true}
                IconComponent={ExpandMoreIcon}
              >
                <MenuItem value={"DESC"}>Latest</MenuItem>
                <MenuItem value={"ASC"}>Oldest</MenuItem>
              </Select>
              <Button
                onClick={() => setShowFilters((show) => !show)}
                sx={{ ...styles.FilterBtn, ...(showFilters ? styles.FilterBtnActif : styles.FilterBtnInactif) }}
              >
                <img
                  style={{ width: "15px", height: "15px" }}
                  src={`/images/${showFilters ? "filterIcon" : "filtersGrayIcon"}.svg`}
                  alt="filters icon"
                />
                <span>{showFilters ? "Filter by" : "Filters"}</span>
              </Button>
              {showFilters && (
                <>
                  <Select
                    defaultValue="Choose a filter"
                    variant="standard"
                    value={filterBy}
                    onChange={handleFilterChange}
                    sx={styles.FilterSelect}
                    disableUnderline={true}
                    IconComponent={ExpandMoreIcon}
                  >
                    <MenuItem value="choose a filter">Choose a filter</MenuItem>
                    <MenuItem value="list">List</MenuItem>
                    <MenuItem value="viewed">Viewed</MenuItem>
                    <MenuItem value="notViewed">Not Viewed</MenuItem>
                    <MenuItem value="prospectName">Prospect Name</MenuItem>
                    <MenuItem value="reposted">Reposted</MenuItem>
                    <MenuItem value="reshared">Reshared</MenuItem>
                  </Select>
                  {filterBy === "prospectName" && (
                    <TextField
                      variant="standard"
                      placeholder="Enter prospect name"
                      value={prospectNameFilter || ""}
                      onChange={(e) => {
                        setProspectNameFilter(e.target.value);
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: { ...styles.FilterInputs, ...styles.ProspectInput },
                      }}
                    />
                  )}
                  {filterBy === "list" && (
                    <Select
                      variant="standard"
                      defaultValue={0}
                      value={listFilter || 0}
                      onChange={(e) => {
                        setListFilter(e.target.value);
                      }}
                      sx={{ ...styles.FilterInputs, ...styles.ListFilter }}
                      disableUnderline={true}
                      IconComponent={ExpandMoreIcon}
                    >
                      <MenuItem value={0}>Choose a list</MenuItem>
                      {lists.map((list) => (
                        <MenuItem key={list.id} value={list.title}>
                          {list.title}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </>
              )}
            </>
          )}

          <Box display="flex" gap="20px" sx={{ marginLeft: "auto" }}>
            {premiumUser ? (
              prospectsData && prospectsData.length > 0 ? (
                <StyledButton
                  disabled={isUpdating}
                  onClick={getUpdates}
                  sx={{
                    "&": { position: "relative" },
                    "& svg": { strokeWidth: "1px", "& path:not(:last-child)": { strokeWidth: "0" } },
                  }}
                >
                  <MdOutlineUpdate />
                  <span>Check updates</span>
                </StyledButton>
              ) : (
                <Tooltip title="Please start by adding some prospects to check their posts." placement="bottom-end">
                  <span>
                    <StyledButton
                      disabled={true}
                      onClick={getUpdates}
                      sx={{
                        "&": { position: "relative" },
                        "& svg": { strokeWidth: "1px", "& path:not(:last-child)": { strokeWidth: "0" } },
                      }}
                    >
                      <MdOutlineUpdate />
                      <span>Check updates</span>
                    </StyledButton>
                  </span>
                </Tooltip>
              )
            ) : null}
            {/* <StyledButton variation="danger" onClick={handleDeletePosts} disabled={isUpdating}>
              Delete all posts
            </StyledButton> */}

            {!premiumUser && currentPagePosts && currentPagePosts.length > 0 && (
              <Modal.Open opens="upgradeModal">
                <StyledButton
                  sx={{
                    "&": { position: "relative", paddingInline: "16px" },
                    "& svg": { strokeWidth: "1px", "& path:not(:last-child)": { strokeWidth: "0" } },
                  }}
                >
                  <MdOutlineUpdate />
                  <span>Check updates</span>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="4px"
                    sx={{
                      borderRadius: "8px",
                      padding: "6px",
                      background: "#FBBE28",
                      color: "#0B0902",
                      height: "24px",
                    }}
                  >
                    <img src="/images/diamond.svg" alt="diamond icon" style={{ height: "16px", width: "16px" }} />
                    <span sx={{ fontSize: "14px", fontWeight: "600", fontFamily: "Inter", lineHeight: "14px" }}>
                      Pro
                    </span>
                  </Box>
                </StyledButton>
              </Modal.Open>
            )}
          </Box>
        </Box>
        <Modal.Window name="upgradeModal" noPadding={true}>
          <UpgradeModal />
        </Modal.Window>

        <Modal.Window
          name="noPostsFound"
          shouldOpen={openNoPostsFound}
          onClose={() => getInitialState()}
          closeIcon={true}
          modalStyles={{ width: "35%" }}
        >
          <NoPostsFoundDialog onCloseModal={() => getInitialState()} />
        </Modal.Window>
        <Modal.Window
          name="cookieErr"
          shouldOpen={isUpdating && showCookieErr}
          onClose={() => getInitialState()}
          noPadding={true}
        >
          <CookieErrDialog getInitialState={getInitialState} />
        </Modal.Window>
      </Modal>

      <Grid container direction="column" spacing={3}>
        <Grid item container spacing={3}>
          {isLoading ? (
            Array.from(new Array(8)).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <PostSkeleton />
              </Grid>
            ))
          ) : isUpdating || (currentPagePosts && currentPagePosts.length > 0) ? (
            <>
              {isUpdating && !openNoPostsFound && !showCookieErr && (
                <Grid item xs={12} sm={6} md={4}>
                  <PourcentageCard
                    error={errorUpdaing}
                    updateFinished={updateFinished}
                    postsFound={postsFound}
                    onSort={() => {
                      getInitialState();
                      setRefreshPostsKey((oldKey) => oldKey + 1);
                    }}
                    onCloseCard={() => {
                      getInitialState();
                      setRefreshPostsKey((oldKey) => oldKey + 1);
                    }}
                    onRetry={() => {
                      getInitialState();
                      getUpdates();
                    }}
                  />
                </Grid>
              )}
              {currentPagePosts &&
                currentPagePosts.length > 0 &&
                currentPagePosts.map((postData) => (
                  <Grid item xs={12} sm={6} md={4} key={postData.id}>
                    <Post postData={postData} />
                  </Grid>
                ))}
            </>
          ) : (
            <NoPosts premiumUser={premiumUser} onCheckUpdates={getUpdates} />
          )}
        </Grid>
      </Grid>

      {!isLoading && currentPagePosts.length > 0 && (
        <Box
          mt="20px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={
            !currentPagePosts.length
              ? { position: "absolute", bottom: "1.5rem", left: "50%", transform: "translateX(-50%)" }
              : {}
          }
        >
          <Pagination
            ressource="Posts"
            count={filteredPosts?.length}
            resulstsChoices={[12, 30, 45, 90]}
            resultsPerPage={resultsPerPage}
            setResultsPerPage={setResultsPerPage}
          />
        </Box>
      )}
    </Paper>
  );
}

export default Index;
