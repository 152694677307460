import React, { useState, useEffect } from 'react';
import Spinner from "../Spinner/Spinner";
import RegisterNewUserService from '../../Services/RegisterNewUserService';
import styles from './Onboarding.module.css';
import loginStyles from '../Login/LoginPage.module.css';
import formStyles from '../Login/FormInput.module.css';
import PasswordStrengthBar from 'react-password-strength-bar';

const CreateAccount = ()=> {
    const [formValues, setFormValues] = useState({
        first_name:'',
        last_name:'',
        email: '',
        password1: '',
        password2:''
        });
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setIsLoading] = useState(false);
    const [pauseImage, setPauseImage] = useState(false);
    const handleMouseEnter = () => {
      setPauseImage(true);
    }
    const handleMouseLeave = () => {
      setPauseImage(false);
      setCurrentIndex(currentIndex + 1);
    }

    const changeImage = (e) => {
      let image = e.currentTarget.id;
      if (image === "lead_monitoring"){
        setCurrentIndex(1);
      }
      else {
        setCurrentIndex(0);
      }
    };
    
  const hashtag_trends = (
    <div style={{background:"#2F3EAC", height:"100vh", flexBasis:"50%"}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div style={{padding:"20px 50px 0px"}}>
          <div style={{textAlign:"center", marginBottom:"15px", height:"117px"}}><img src='/images/1_hashtag.svg' alt='FILT Pod'></img></div>
          <div style={{color:"white", textAlign:"center", height:"500px"}}>
            <div style={{height:"90px", marginBottom:"40px"}}>
              <div style={{lineHeight:"28px", fontSize:"16px", fontWeight:"400"}}>
                <div>Seize LinkedIn hashtags with rapid follower growth for more content exposure by monitoring their behavioral trends.</div>
              </div>
            </div>
            <div style={{background:"white", borderRadius:"20px", padding:"10px"}}>
              <img src='/images/hashtag_trends_description_small.jpg' alt='Hashtag Trends'></img>
            </div>
          </div>
          <div >
            <div style={{width:"max-content", margin:"auto"}} className='row'>
              <div id="hashtag_trends" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse_selected.svg' alt=''></img></div>
              <div id="lead_monitoring" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse.svg' alt=''></img></div>
              
            </div>
          </div>
        </div>
    </div>
)

const lead_monitoring = (
    <div style={{background:"#2F3EAC", height:"100vh", flexBasis:"50%"}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div style={{padding:"20px 50px 0px"}}>
          <div style={{textAlign:"center", marginBottom:"15px", height:"117px"}}><img src='/images/2_engage.svg' alt='FILT Pod'></img></div>
            <div style={{color:"white", textAlign:"center", height:"500px"}}>
              <div style={{height:"90px", marginBottom:"40px"}}>
                <div style={{lineHeight:"28px", fontSize:"16px", fontWeight:"400"}}>
                Use AI to write insightful comments, saving hours of your time by engaging and increasing touchpoints with multiple LinkedIn leads for faster conversions.
                </div>
              </div>
              <div style={{background:"white", borderRadius:"20px", padding:"10px"}}>
                <img src='/images/lead_monitoring.jpg' alt='Lead Monitoring'></img>
              </div>
            </div>
            <div >
              <div style={{width:"max-content", margin:"auto"}} className='row'>
                <div id="hashtag_trends" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse.svg' alt=''></img></div>
                <div id="lead_monitoring" style={{margin:"20px 5px", cursor:"pointer"}} onClick={changeImage}><img src='/images/ellipse_selected.svg' alt=''></img></div>
                
              </div>
            </div>
        </div>
    </div>
)

  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (pauseImage) {
        return
      }
      if (currentIndex >= 1) {
        setCurrentIndex(0)
      }
      else {
        setCurrentIndex(currentIndex + 1)
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [currentIndex, pauseImage]);

    const handleChange = (event) => {
        setErrorMessage();
        event.persist();
        var filteredStr = filterMetacharacters(event.target.value);
        if (event.target.name === "email") {
          filteredStr = filterEmailAlias(filteredStr);
        }
        console.log(filteredStr)
        setFormValues((values) => ({
            ...values,
            [event.target.name]: filteredStr
        }));
        if (event.target.id === 'password1') {
          let pw1 = document.getElementById("password1").value;
          if (pw1.length < 8) {
            setErrorMessage("Password is too short");
            return
          }
        }
        if (event.target.id === 'password1' || event.target.id === 'password2') {
            let pw1 = document.getElementById("password1").value;
            let pw2 = document.getElementById("password2").value;
            if (pw1!==pw2){
                setErrorMessage("Passwords do not match");
                return
            }
        }
    };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    // check user source
    var group = 'Chrome Extension';
    const queryParams = new URLSearchParams(window.location.search);
    const source = queryParams.get('source');
    if (source === 'engageai') {
        group = 'Engage AI'
    }
    RegisterNewUserService(formValues.first_name, formValues.last_name, formValues.email, formValues.password1, group).then((res)=> {
        if (res.status === 200) {
            setSuccess(true);
            const d = new Date();
            d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
            const expires = "expires="+d.toUTCString();
            document.cookie = "fp_email=" + formValues.email + ";" + expires + "; path=/";
            var email=formValues.email;
            window.fpr("referral",{email: email})
        }
        else {
            setErrorMessage('Perhaps you already have an account with us? If so, you may proceed to log in.');
        }
        setIsLoading(false);

    }).catch(() => {
        setErrorMessage('An error occured. If this persists, please contact hello@engage-ai.co and our team will assist you.');
        setIsLoading(false);
    })
    
};

const openLinkedinAuth = () => {
  const state = formValues.email;
  localStorage.setItem("linkedin_state", state);
  /** 
  var redirect_uri = "http://localhost:3000/"
  if (window.location.hostname.includes("filtpod-frontend-test")) {
    redirect_uri = "https://filtpod-frontend-test.web.app/LinkedinAuthRedirect";
  }
  else if (window.location.hostname.includes("apps.engage-ai")){
    redirect_uri = "https://apps.engage-ai.co/LinkedinAuthRedirect";
  }
  window.open(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86vxx2jtebvxju&redirect_uri=${redirect_uri}&state=${state}&scope=r_liteprofile%20r_emailaddress`)
 */
  window.open(`https://www.linkedin.com/`)
};

function filterMetacharacters(inputString) {
  inputString = inputString.replace(/<\/?script[^>]*>/gi, '').replace(/<\/?html[^>]*>/gi, '');
  return inputString;
}

function filterEmailAlias(inputString) {
  inputString = inputString.replace(/\+[^@]+@/, '@');
  return inputString;
}
 
  return (
    <div className={`${loginStyles.main} flex-nowrap `}>
      <div className={`${styles.row}`}>
        {currentIndex === 1 ? lead_monitoring : hashtag_trends}
        <div className={`${loginStyles.rightbackground} col-6 `}>
          {loading ? <Spinner className="spinner-container" /> : ""}
          {success ? (
            <div className='form-row d-flex justify-content-center'>
              <div className='col-md-8 mt-3' >
                <div style={{fontSize:"24px", fontWeight:"600", lineHeight:"36px"}}>Step 1 : Check your email <img src='/images/email.svg' alt='email.svg' style={{marginBottom:"10px"}}></img></div>
                <div className='mt-3'>We sent a verification link to {formValues.email}. Please be sure to check your spam folder too.</div>
                <div className={`${formStyles['rect-button']}`} style={{display:"flex", alignItems:"center", marginTop:"23px", cursor:"pointer"}} onClick={()=> {window.open("https://mail.google.com/mail/u/0/#search/from%3A(%40engage-ai.co)+in%3Aanywhere");}}>
                  <div style={{margin:"0 5px"}}><img src='/images/gmail.jpg' alt='gmail' style={{width:"20px"}}></img></div>
                  <div style={{fontWeight:"bold"}}>Open in Gmail</div>
                  <div style={{margin:"0 5px"}}><img src='/images/link-external.jpg' alt='link-external' style={{width:"15px"}}></img></div>
                </div>
                <div style={{marginTop:"40px", fontSize:"24px", fontWeight:"600", lineHeight:"36px"}}>Step 2 : Log Into LinkedIn and Refresh the Page</div>
                <div className='mt-3'></div>
                <div className={`${formStyles['rect-button']}`} style={{display:"flex", alignItems:"center", marginTop:"23px", cursor:"pointer"}} onClick={openLinkedinAuth}>
                  <div style={{margin:"0 5px"}}><img src='/images/linkedin_logo.svg' alt='gmail' style={{width:"20px"}}></img></div>
                  <div style={{fontWeight:"bold"}}>Login to LinkedIn</div>
                  <div style={{margin:"0 5px"}}><img src='/images/link-external.jpg' alt='link-external' style={{width:"15px"}}></img></div>
                </div>
                <div className='d-flex justify-content-center' style={{marginTop:"200px"}}>
                  <div><span><img src='/images/back-arrow.svg' alt='back to login'></img></span> Back to <span style={{background:"#F8F8F8", boxShadow:"0px 2px 4px rgba(63, 90, 154, 0.25)", borderRadius:"100px", padding:"4px 16px", fontWeight:"600"}}><a href='/'>Login</a></span></div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <form onSubmit={handleSubmit}>
                <div>
                  <div className='form-row d-flex justify-content-center'>
                    
                    <div className='col-md-8 mt-3'>
                        <div className={`${styles['frow']}`}>
                            <div className={`${styles['fcolumn']}`}>
                                <label htmlFor='first_name'>First Name</label>
                                <input
                                    className={`${formStyles['form-input3']} mt-2`}
                                    type='text'
                                    id='first_name'
                                    name='first_name'
                                    onChange={handleChange}
                                    value={formValues.first_name}
                                    style={{width:"230px"}}
                                    required
                                />
                            </div>
                            <div className={`${styles['fcolumn']}`}>
                            <label htmlFor='last_name'>Last Name</label>
                                <input
                                    className={`${formStyles['form-input3']} mt-2`}
                                    type='text'
                                    id='last_name'
                                    name='last_name'
                                    onChange={handleChange}
                                    value={formValues.last_name}
                                    style={{width:"230px"}}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className=' col-md-8 mt-3'>
                        <div className={`${styles['fcolumn']}`}>
                            <label htmlFor='email'>Email</label>
                            <input
                                className={`${formStyles['form-input3']} mt-2`}
                                placeholder='user@gmail.com'
                                type='email'
                                id='email'
                                name='email'
                                onChange={handleChange}
                                value={formValues.email}
                                required
                            />
                        </div>
                    </div>
                    <div className='col-md-8 mt-3'>
                        <div className={`${styles['fcolumn']}`}>
                            <label htmlFor='password1'>Password</label>
                            <input
                                className={`${formStyles['form-input3']} mt-2`}
                                placeholder='Password (at least 8 characters)'
                                type='password'
                                id='password1'
                                name='password1'
                                onChange={handleChange}
                                autoComplete='new-password'
                                value={formValues.password1}
                                required
                            />
                        </div>
                        <PasswordStrengthBar password={formValues.password1} minLength={8}/>
                    </div>
                    <div className='col-md-8 mt-3'>
                        <div className={`${styles['fcolumn']}`}>
                            <label htmlFor='password2'>Confirm Password</label>
                            <input
                                className={`${formStyles['form-input3']} mt-2`}
                                placeholder='Re-Enter Password'
                                type='password'
                                id='password2'
                                name='password2'
                                onChange={handleChange}
                                autoComplete='new-password'
                                value={formValues.password2}
                                required
                            />
                        </div>
                    </div>
                    <div className='d-flex col-md-12 justify-content-center' style={{color:"#A91212"}}>
                      {errorMessage}
                    </div>
                    <div className='form-group col-md-8 mt-4'>
                      <div style={{fontWeight:"600", fontSize:"12px", marginBottom:"10px", textAlign:"center"}}>I’ve reviewed and agree to the <a href='https://engage-ai.co/terms-and-conditions/' target='_blank' rel="noopener noreferrer">Terms and Conditions</a>.</div>
                      <button className={`${formStyles['submit-button']}`} data-testid='newpassword-submit' disabled={errorMessage ? true : false}>
                        Sign Up
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div className='d-flex justify-content-center'>
                <div>Already have an account? <span style={{background:"#F8F8F8", boxShadow:"0px 2px 4px rgba(63, 90, 154, 0.25)", borderRadius:"100px", padding:"4px 16px", fontWeight:"600"}}><a href='/'>Login</a></span></div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default CreateAccount;