// -------------------------------------------------------------------------
// IMPORTS
// -------------------------------------------------------------------------
import { useMemo, useState } from "react";
import { Controller, Form, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import toast from "react-hot-toast";

import { AddNewProspectService, EditProspectsService } from "../../../../Services/GetProspectsPosts";

import StyledButton from "../../ReusableComponents/Button";
import { StyledSwitch } from "../../ReusableComponents/StyledSwitch";
import { useNurtureContext } from "../../../../Context/NurtureContext";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  styled,
} from "@mui/material";
import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { FaLinkedin } from "react-icons/fa";
import { FaUserPlus } from "react-icons/fa6";
import { MdDiamond } from "react-icons/md";
import { FaUserEdit } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { FaCircleExclamation } from "react-icons/fa6";
import SyncErrAfterAdd from "./SyncErrAfterAdd";

// -------------------------------------------------------------------------

const styles = {
  Modal: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  closeBtn: {
    background: "#ecedee",
    border: "none",
    padding: "8px",
    borderRadius: "3px",
    transition: "all 0.2s",
    height: "40px",
    width: "40px",
    minWidth: "40px",

    "&:hover": {
      background: " #ecedeecc",
    },

    "& svg ": {
      width: "24px",
      height: "24px",
      color: "#1f292e",
    },
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  formLabel: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "600",
    color: "var(--color-grey-400)",
  },
  errMsg: {
    fontSize: "12px",
    fontWeight: "700",
    lineHeight: "16px",
    color: "var(--color-red)",
    margin: "0",
  },
  link: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  upgradeMsg: {
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "16px",
    color: "var(--color-warning)",
    borderTop: "1px solid #E0E2E4",
    background: "#fffaf2",
    padding: "8px 16px",
    textAlign: "center",
  },
  formErr: {
    fontSize: "14px",
    fontWeight: "500",
    padding: "8px 16px",
    color: "var(--color-red)",
    background: "var(--color-red-100)",
    borderRadius: "8px",
  },
  select: {
    padding: "16px",
    border: "1px solid #e0e2e4",
    borderRadius: "8px",
    height: "51px",
    fontFamily: "Inter",
    fontWeight: "400",
    "& .MuiSelect-select:focus": {
      background: "none",
    },
    "& svg": {
      marginRight: "16px",
    },
  },
  monitoringBox: {
    border: "1px solid #E0E2E4",
    borderRadius: "8px",
    "& .monitoringText": {
      fontWeight: "500",
      fontSize: "12px",
      color: "#415058",
    },
  },
};

const StyledTextField = styled(TextField)`
  border: 1px solid #e0e2e4;
  border-radius: 8px;

  & .Mui-disabled {
    background: #a0a7ab1a !important;
  }

  & input {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    color: #1f292e;
    padding: 0;

    &.Mui-disabled {
      color: #1f292e !important;
      -webkit-text-fill-color: #1f292e !important;
      background: none !important;
    }

    &::placeholder {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      color: #a0a7ab;
      font-style: normal;
    }
  }
  & textarea {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    color: #1f292e;
    padding: 0;

    &::placeholder {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      color: var(--color-grey-400);
      font-style: normal;
    }
  }
`;

function AddUpdateProspect({ open, onClose, mode, prospectData = {}, monitoredProspects = [], setProspect }) {
  const { lists, setRefreshProspectsKey } = useNurtureContext();
  const { prospectsLimit } = useOutletContext();

  const [monitorProspect, setMonitorProspect] = useState(monitoredProspects.length < prospectsLimit && true);
  const [showErrForm, setShowErrForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  // const [profileNotFound, setProfileNotFound] = useState(false);
  const [profileExists, setProfileExists] = useState(false);
  const [cookieErr, setCookieErr] = useState(false);

  const regex = useMemo(() => {
    // eslint-disable-next-line no-useless-escape
    return /^((https?):\/\/)?(www\.)?linkedin\.com\/(in|pub|company)\/([a-zA-Z0-9\-]|%[0-9a-fA-F]{2})+(\/[0-9]{0,8})?$/;
  }, []);

  const formDefaultValues = useMemo(() => {
    if (mode === "add") {
      return {
        LinkedIn_profile: "",
        list_id: "",
      };
    } else {
      return {
        ...prospectData,
        LinkedIn_profile: prospectData["LinkedIn profile"] || "",
        headline: prospectData.headline || "",
        first_name: prospectData["first name"] || "",
        last_name: prospectData["last name"] || "",
        list_id: lists.find((list) => list.id === prospectData["list id"])?.title || "",
      };
    }
  }, [mode, prospectData, lists]);

  const {
    formState: { errors, isDirty },
    handleSubmit,
    control: prospectController,
    watch,
  } = useForm({
    mode: "onBlur",
    defaultValues: formDefaultValues,
    values: formDefaultValues,
  });

  const fields = watch();

  const transformFormDataAdd = (formData) => {
    const newObject = {};
    for (const [key, value] of Object.entries(formData)) {
      const newKey = key.replace(/_/g, " ");
      newObject[newKey] = value;
    }

    return newObject;
  };

  const transformFormDataUpdate = (formData, link) => {
    const newObject = {};
    let newKey;
    for (const [key, value] of Object.entries(formData)) {
      if (link) {
        newKey = key.replace(/ /g, "_");
      } else {
        newKey = key.replace(/_/g, " ");
      }
      newObject[newKey] = value;
    }
    return newObject;
  };

  const onSubmit = async (formData) => {
    if (mode === "edit") {
      handleUpdate(formData);
    } else {
      handleAdd(formData);
    }
  };

  const handleUpdate = async (data) => {
    try {
      setShowErrForm(false);
      setIsSaving(true);
      const { list_id, headline, first_name, last_name, LinkedIn_profile, id, is_monitoring } = data;
      const listId = lists.find((list) => list.title === list_id).id;
      const newObj = {
        list_id: listId,
        headline,
        first_name,
        last_name,
        LinkedIn_profile,
        id,
        monitoring: is_monitoring,
      };
      const transformedFormData = transformFormDataUpdate(newObj, false);
      const response = await EditProspectsService({
        data: [{ ...transformedFormData }],
      });
      if (response.ok) {
        toast.success("Prospect updated successfully");
        setRefreshProspectsKey((key) => key + 1);
        onClose();
      }
      if (!response.ok) throw response;
    } catch (err) {
      setShowErrForm(true);
    } finally {
      setIsSaving(false);
    }
  };
  const handleAdd = async (data) => {
    try {
      setShowErrForm(false);
      setProfileExists(false);
      setIsSaving(true);

      const transformedData = transformFormDataAdd(data);
      const list = lists.find((list) => list.title === data.list_id);

      transformedData["list id"] = list.id;
      transformedData.monitoring = monitorProspect;

      transformedData["first name"] = transformedData["last name"] = null;

      const response = await AddNewProspectService(transformedData);
      const responseData = await response.json();

      if (response.ok) {
        setRefreshProspectsKey((key) => key + 1);
        setProspect({
          success: true,
          list: list.title,
          imageUrl: responseData?.profile_photo_url,
          firstName: responseData?.first_name,
          lastName: responseData?.last_name,
        });
        onClose();
      }

      if (responseData.error) throw responseData.error;
      if (!response.ok && response.status === 400) throw responseData?.message;
    } catch (err) {
      if (err?.includes("LinkedIn cookie is missing") || err?.includes("Cannot retrieve prospect's details")) {
        setCookieErr(true);
      } else if (err?.includes("prospect already exists")) {
        setProfileExists(true);
      } else {
        setShowErrForm(true);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const canSave = useMemo(() => {
    if (mode === "add" && fields["list_id"] && fields["LinkedIn_profile"] && regex.test(fields["LinkedIn_profile"]))
      return true;
    if (isSaving) return false;
    if (mode === "edit" && isDirty) return true;
    return false;
  }, [fields, regex, isDirty, mode, isSaving]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={"sm"}
        PaperProps={{
          sx: {
            borderRadius: "16px",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08), 0px 0px 4px rgba(0, 0, 0, 0.04)",
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" mb="8px">
            <Box display="flex" alignItems="center" gap="10px" flexGrow={1}>
              {mode === "add" ? (
                <FaUserPlus style={{ height: "24px", width: "24px" }} />
              ) : (
                <FaUserEdit style={{ height: "24px", width: "24px" }} />
              )}
              <h2 style={{ fontSize: "24px", fontWeight: "700", color: "#1F292E", margin: "0" }}>
                {mode === "add" ? "Add prospect" : "Edit prospect"}
              </h2>
            </Box>
            <Button sx={styles.closeBtn} onClick={onClose}>
              <IoClose />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box mb="24px">
            <span style={{ fontWeight: "500", fontSize: "14px", color: "#415058" }}>
              {mode === "add" ? "Add necessary details about your prospect" : "Update your prospect details"}
            </span>
          </Box>
          <Form id="addUpdateForm" control={prospectController} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={styles.Modal}>
              <Box display="flex" flexDirection="column" gap="1.4rem">
                <Box sx={styles.formGroup}>
                  <FormLabel sx={styles.formLabel}>
                    {mode === "add" ? "Linkedin profile or company URL" : "LinkedIn URL"}
                  </FormLabel>
                  <Controller
                    name="LinkedIn_profile"
                    control={prospectController}
                    rules={{
                      validate: (value) => {
                        if (fields["LinkedIn_profile"]) {
                          return regex.test(value) || "Invalid LinkedIn profile link";
                        }
                      },
                    }}
                    render={({ field }) => (
                      <StyledTextField
                        {...field}
                        variant="standard"
                        placeholder="Add a LinkedIn profile or company URL"
                        disabled={mode === "edit"}
                        sx={{
                          border:
                            profileExists || (fields["LinkedIn_profile"] && errors?.LinkedIn_profile)
                              ? "1px solid var(--color-red)"
                              : "1px solid #e0e2e4",
                        }}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <Box
                              sx={{
                                py: "14px",
                                px: "16px",
                                mr: "8px",
                                background: mode === "add" ? "#A0A7AB1A" : "none",
                                borderRight: mode === "edit" ? "1px solid #e0e2e4" : "none",
                              }}
                            >
                              <FaLinkedin style={{ height: "18px", width: "18px", color: "var(--color-brand)" }} />
                            </Box>
                          ),
                        }}
                      />
                    )}
                  />
                  {(profileExists || (fields["LinkedIn_profile"] && !!errors?.LinkedIn_profile)) && (
                    <Typography sx={styles.errMsg}>
                      {" "}
                      {profileExists ? "Prospect already exists" : errors?.LinkedIn_profile?.message}
                    </Typography>
                  )}
                </Box>
                {mode === "edit" && (
                  <Box display="flex" alignItems="center" gap="24px">
                    <Box sx={styles.formGroup}>
                      <FormLabel sx={styles.formLabel}>First Name</FormLabel>
                      <Controller
                        name="first_name"
                        control={prospectController}
                        render={({ field }) => (
                          <StyledTextField
                            {...field}
                            variant="standard"
                            placeholder="Edit your prospect's first name"
                            sx={{
                              "& input": {
                                padding: "14px 16px !important",
                              },
                            }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Box sx={styles.formGroup}>
                      <FormLabel sx={styles.formLabel}>Last Name</FormLabel>
                      <Controller
                        name="last_name"
                        control={prospectController}
                        render={({ field }) => (
                          <StyledTextField
                            {...field}
                            variant="standard"
                            placeholder="Edit your prospect's last name"
                            sx={{
                              "& input": {
                                padding: "14px 16px !important",
                              },
                            }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                )}
                {mode === "edit" && (
                  <Box sx={styles.formGroup}>
                    <FormLabel sx={styles.formLabel}>Headline</FormLabel>
                    <Controller
                      name="headline"
                      control={prospectController}
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          variant="standard"
                          multiline
                          minRows={1}
                          placeholder="Add a headline to your prospect"
                          sx={{
                            "& textarea[name='headline']": {
                              padding: "14px 16px !important",
                            },
                          }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      )}
                    />
                  </Box>
                )}
                <Box sx={styles.formGroup}>
                  <FormLabel sx={styles.formLabel}>Segment</FormLabel>
                  <Controller
                    name="list_id"
                    control={prospectController}
                    render={({ field }) => (
                      <Select
                        {...field}
                        sx={{
                          ...styles.select,
                          color: field.value === "" ? " #a0a7ab" : "inherit",
                          opacity: field.value === "" ? "0.65" : "1",
                        }}
                        displayEmpty
                        variant="standard"
                        disableUnderline={true}
                      >
                        <MenuItem key={0} value="">
                          Choose a list
                        </MenuItem>
                        {lists?.length &&
                          lists.map((list) => (
                            <MenuItem key={list.id} value={list.title}>
                              {list.title}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </Box>

                {mode === "add" && (
                  <Box sx={styles.monitoringBox}>
                    <Box sx={{ padding: "16px" }} display="flex" alignItems="center">
                      <Box display="flex" flexGrow={1} flexDirection="column" gap="8px">
                        <FormLabel sx={styles.formLabel}>Monitoring</FormLabel>
                        <span className="monitoringText">
                          Receive updates for this prospect in the next monitoring session.
                        </span>
                      </Box>
                      <StyledSwitch
                        disabled={monitoredProspects.length >= prospectsLimit}
                        checked={monitorProspect}
                        onChange={(ev) => setMonitorProspect(ev.target.checked)}
                      />
                    </Box>
                    {monitoredProspects.length >= prospectsLimit && (
                      <Box display="flex" alignItems="center" gap="8px" sx={styles.upgradeMsg}>
                        <MdDiamond style={{ height: "18px", width: "18px" }} />
                        <span>
                          You have reached your plan limit.{" "}
                          <a
                            href="https://bit.ly/3xdvInj"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              fontWeight: "600",
                              color: "var(--color-warning)",
                              textDecoration: "underline",
                            }}
                          >
                            Upgrade to monitor more prospects.
                          </a>
                        </span>
                      </Box>
                    )}
                  </Box>
                )}

                {showErrForm && (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="8px"
                    sx={{ background: "rgba(251, 233, 233, 1)", padding: "16px" }}
                  >
                    <FaCircleExclamation style={{ height: "16px", width: "16px", color: "var(--color-red)" }} />
                    <span style={styles.errMsg}>An unknown error occured. Please try again later.</span>
                  </Box>
                )}
              </Box>
            </Box>
          </Form>
        </DialogContent>
        <DialogActions>
          <Box sx={{ width: "100%" }} display="flex" justifyContent="space-between" px="24px" py="16px">
            <StyledButton onClick={onClose} variation="noBackgroundPrimary">
              Cancel
            </StyledButton>
            <StyledButton
              sx={{ "& span": { color: "white", width: "24px !important", height: "24px !important" } }}
              disabled={!canSave}
              type="submit"
              form="addUpdateForm"
            >
              {isSaving ? <CircularProgress /> : mode === "add" ? "Add prospect" : "Save"}
            </StyledButton>
          </Box>
        </DialogActions>
      </Dialog>
      {cookieErr && (
        <SyncErrAfterAdd
          open={cookieErr}
          onClose={() => {
            setCookieErr(false);
            onClose();
          }}
        />
      )}
    </>
  );
}

export default AddUpdateProspect;
