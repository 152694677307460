export function formatAMPM(timeString) {
  if (/\d{2}:\d{2}/.test(timeString)) {
    var match = timeString.match(/(\d{2}):(\d{2})/);
    var hours = Number(match[1]);
    var minutes = Number(match[2]);
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  } else return "";
}
