import React from 'react';
import styles from './Onboarding.module.css';
import Topbar from './Topbar'

const ExitSurveyEngageAI = () => {
    return (
        <div>
            <Topbar/>
            <div className={`${styles['main']} ${styles['center-align']}`} style={{minHeight:"0px"}}>
                <div style={{color:"#3947A8"}}>
                    <p style={{fontWeight:"700", fontSize:"36px", color:"#3947A8"}}>We are sorry to see you go</p>
                    <p style={{marginBottom:"50px"}}>Help us understand what we can do to improve <b>Engage AI</b> and, hopefully, stand a chance to see you take it for another spin!</p>
                    <div style={{margin:"40px 0 20px"}}><p>This super short survey takes less than 30 seconds:</p></div>
                    <div style={{background:"#3947A8", padding:"12px", borderRadius:"8px", width:"max-content", margin:"auto"}}><a style={{textDecoration:"none", color:"white"}} href='https://docs.google.com/forms/d/e/1FAIpQLSdXaWlhmqgAno4ROlMWH8qhq4hD9pvI11yH6wXqpFi6duKgdg/viewform' target='__blank'>Start Survey</a></div>
                </div>
            </div>
            <div style={{padding:"40px 80px", textAlign:"center", fontWeight:"400", background:"#3947A8", color:"white", height:"50vw"}}>
                <p>Having issues with the <b>Engage AI</b> extension?</p>
                <div style={{display:"flex", gap:"20px", alignItems:"center", justifyContent:"center"}}>
                    <div><a href="mailto: hello@engage-ai.co" style={{color:"white", textDecoration:"underline"}}>Contact Support</a></div>
                    <div style={{width:"max-content", padding:"12px", background:"white", borderRadius:"8px"}}><a href="https://chrome.google.com/webstore/detail/engage-ai-openai-gpt-for/nelhhkchoapcbpcgpmmiahfkcdhgecaf?hl=en-GB&authuser=0" target='__blank' style={{color:"#3947A8"}}>Reinstall Engage AI</a></div>
                </div>
                <div style={{fontWeight:"400", fontSize:"14px", marginTop:"30px"}}>...and we will gladly help you solve your problems!</div>
            </div>
        </div>
    );
}
export default ExitSurveyEngageAI;