import domain from "./DomainService";

/**
 * Get the particular pod's cutoff time and next next run time in local and utc times
 * Uses JWT token to identify user
 * @return {Promise}
 */
export default function GetCutoffTime() {
  const token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    //body: JSON.stringify({ pod_id: "TIC1" }),
  };
  let pod_id = "TIC1";

  return fetch(domain() + "submit-link?pod_id=" + pod_id, options);
}
