import React from 'react';
import styles from './Onboarding.module.css';
import Topbar from './Topbar'; 

const WelcomeEngageAI = () => {  
    return (
        <div >
        <div className={`${styles['body']} `}>
            <Topbar/>
            <div className={`${styles['top-arrow']}`} style={{background:"#3947A8", padding:"24px", textAlign:"center", color:"white"}}>
                <h1>
                    Find everything you need here
                </h1>
                <img style={{width:"800px", marginTop:"20px"}} src='/images/pin_engageai.gif' alt=''></img>
                <div style={{padding:"12px", background:"white", borderRadius:"8px", width:"max-content", margin:"22px auto"}}>
                    <a href='/Onboarding/EngageAI/WhyEngageAI' style={{color:"#3947A8", textDecoration:"none", fontWeight:800}}>Next</a>
                </div>
                <div>1 of 2</div>
            </div>
        </div>
        </div>
    );
}
export default WelcomeEngageAI;

