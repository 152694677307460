import React, { useState, useContext } from "react";
import Spinner from "../Spinner/Spinner";
import CutoffTimeComponent from "./CutoffTIme";
import styles from "./LinkSubmitPage.module.css";
import { Context } from "../../Store/Store";
import { deleteLinkDataLocalStorage, deleteGeneratedResponsesLocalStorage } from "../../Scripts/getSetLocalStorage";
import LinkSubmitForm from "./LinkSubmitForm";
import SaveDeviceService from "../../Services/SaveDeviceService";
import app from "../../firebase";
import { getMessaging, getToken } from "firebase/messaging";

const messaging = getMessaging(app);

const timeIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.098 10.634L11 9.423V5C11 4.73478 10.8946 4.48043 10.7071 4.29289C10.5196 4.10536 10.2652 4 10 4C9.73479 4 9.48043 4.10536 9.2929 4.29289C9.10536 4.48043 9 4.73478 9 5V10C9.00001 10.1755 9.04622 10.348 9.13399 10.5C9.22175 10.652 9.34799 10.7782 9.5 10.866L12.098 12.366C12.2118 12.4327 12.3376 12.4762 12.4683 12.4941C12.5989 12.512 12.7319 12.5038 12.8594 12.4701C12.9869 12.4364 13.1064 12.3778 13.2112 12.2977C13.3159 12.2176 13.4038 12.1175 13.4698 12.0033C13.5357 11.8891 13.5784 11.763 13.5954 11.6322C13.6124 11.5014 13.6034 11.3686 13.5688 11.2413C13.5343 11.114 13.4749 10.9949 13.3941 10.8906C13.3133 10.7864 13.2127 10.6992 13.098 10.634ZM10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34873 18.9426 4.80688 17.0679 2.93215C15.1931 1.05742 12.6513 0.0029116 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C17.9974 12.1209 17.1537 14.1542 15.6539 15.6539C14.1542 17.1536 12.1209 17.9974 10 18Z"
      fill="#315DDA"
    />
  </svg>
);

/** 
  
const timeIcon2 = (
<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 500 500'>
    <path
    className='cls-1'
    fill='#23324E'
    d='M160.37,397.4a2.84,2.84,0,0,1-1.94-.71L102.14,340.4a2.9,2.9,0,0,1-.73-1.91V173.83a2.76,2.76,0,0,1,.71-1.88l56.36-56.37a2.41,2.41,0,0,1,1.89-.78H325a2.35,2.35,0,0,1,1.88.77l56.37,56.37a2.6,2.6,0,0,1,.79,1.89V338.49a2.67,2.67,0,0,1-.84,1.95L327,396.74a3,3,0,0,1-1.91.66Zm1.35-5,162.63-.25,54.67-55-.24-162.69-55-54.66L161.48,120l-55.07,55,.24,162.69Z'
    />
    <path
    className='cls-1'
    fill='#23324E'
    d='M160.37,397.25a2.72,2.72,0,0,1-1.84-.67l-56.29-56.29a2.75,2.75,0,0,1-.68-1.8V173.83a2.56,2.56,0,0,1,.67-1.77l56.36-56.37a2.22,2.22,0,0,1,1.78-.74H325a2.2,2.2,0,0,1,1.77.72l56.37,56.38a2.37,2.37,0,0,1,.75,1.78V338.49a2.54,2.54,0,0,1-.8,1.85l-56.29,56.3a2.78,2.78,0,0,1-1.8.61ZM106.26,175l.24,162.82,55.16,54.74,162.76-.25,54.75-55.08-.24-162.82-55.1-54.74-162.41.24Z'
    />
    <path
    className='cls-1'
    fill='#23324E'
    d='M242.71,271.86a15.64,15.64,0,0,1-3.89-30.78l1.26-.33V196.46a2.63,2.63,0,0,1,2.63-2.68,2.66,2.66,0,0,1,2.69,2.68v44.32l1.3.3a14.29,14.29,0,0,1,4.21,1.73l1.11.67L297.27,200a2.55,2.55,0,0,1,3.71.06,2.6,2.6,0,0,1-.06,3.68l-45.28,43.63.66,1.14a15.55,15.55,0,0,1,2.1,7.64A15.72,15.72,0,0,1,242.71,271.86Zm0-26.13a10.37,10.37,0,1,0,10.38,10.43A10.41,10.41,0,0,0,242.71,245.73Z'
    />
    <path
    className='cls-1'
    fill='#23324E'
    d='M242.7,271.7a15.49,15.49,0,0,1-3.84-30.48l1.37-.36v-44.4a2.48,2.48,0,0,1,2.47-2.53,2.52,2.52,0,0,1,2.55,2.53V240.9l1.42.33a13.7,13.7,0,0,1,4.16,1.71l1.21.73,45.33-43.55a2.41,2.41,0,0,1,3.5,0,2.46,2.46,0,0,1-.06,3.47l-45.35,43.71.71,1.24a15.38,15.38,0,0,1,2.08,7.57A15.56,15.56,0,0,1,242.7,271.7Zm0-26.12a10.52,10.52,0,1,0,10.54,10.58A10.56,10.56,0,0,0,242.7,245.58Z'
    />
    <path
    className='cls-1'
    fill='#23324E'
    d='M242.71,371.41A115.32,115.32,0,1,1,358,256.16C358,319.71,306.29,371.41,242.71,371.41Zm0-23.13a2.64,2.64,0,0,1,2.69,2.57v15.26l1.75-.07a108.09,108.09,0,0,0,46.72-12.54l1.55-.82-7.57-13.09a2.59,2.59,0,0,1,.91-3.62,5.46,5.46,0,0,1,1.42-.23,2.31,2.31,0,0,1,2.2,1.15L300,350l1.47-.93a109.77,109.77,0,0,0,34.18-34.25l.93-1.48-13.1-7.57a2.44,2.44,0,0,1-1.22-1.52,2.67,2.67,0,0,1,.25-2.1,2.57,2.57,0,0,1,2.25-1.26l13,7,1.52.88.82-1.54a109.18,109.18,0,0,0,12.54-46.8l.07-1.75H337.53a2.63,2.63,0,1,1,0-5.25h15.19l-.07-1.76a108.68,108.68,0,0,0-12.53-46.77l-.83-1.55-13.36,7.83-1,.06a2.51,2.51,0,0,1-2.37-1.2,2.76,2.76,0,0,1-.26-2.15,2.38,2.38,0,0,1,1.2-1.49l13.12-7.59-.94-1.47a111.93,111.93,0,0,0-34.16-34.24l-1.49-.95-7.64,13.24a2.67,2.67,0,0,1-2.26,1.22c-1.61-.48-2.34-1-2.56-1.88a2.57,2.57,0,0,1,.28-2l7.58-13.25-1.56-.81a109.57,109.57,0,0,0-46.71-12.55l-1.75-.07v15.27a2.67,2.67,0,0,1-2.69,2.69,2.63,2.63,0,0,1-2.63-2.69V146.08l-1.75.07a110.19,110.19,0,0,0-46.76,12.55l-1.56.81,7.63,13.23a2.84,2.84,0,0,1-1,3.61l-.19.11-1,.07a2.66,2.66,0,0,1-2.46-1.18l-7.51-13.16-1.49.95a112.2,112.2,0,0,0-34.23,34.22l-1,1.5L162,206.42A2.61,2.61,0,0,1,163.2,208a2.64,2.64,0,0,1-2.56,3.38l-12.9-7.1-1.54-.89-.83,1.56a108.15,108.15,0,0,0-12.53,46.76l-.07,1.76H148a2.63,2.63,0,0,1,0,5.25h-15.2l.07,1.75a108.9,108.9,0,0,0,12.54,46.8l.82,1.54,13.16-7.57a5.16,5.16,0,0,1,1.34-.2,2.41,2.41,0,0,1,2.28,1.14,2.64,2.64,0,0,1,.18,2,2.56,2.56,0,0,1-1.16,1.59l-13.19,7.58.94,1.49A109.93,109.93,0,0,0,184,349.07l1.49.93L193,336.91a2.67,2.67,0,0,1,2.28-1.35,3.72,3.72,0,0,1,2.49,2.05,2.46,2.46,0,0,1-.2,2L190,352.68l1.56.82A108.38,108.38,0,0,0,238.33,366l1.75.07V350.85A2.63,2.63,0,0,1,242.71,348.28Z'
    />
    <path
    className='cls-1'
    fill='#23324E'
    d='M242.7,371.26a115.26,115.26,0,0,1-115.15-115.1c0-63.54,51.66-115.23,115.15-115.23A115.33,115.33,0,0,1,357.88,256.16C357.88,319.62,306.21,371.26,242.7,371.26Zm-51.21-17.63a108.73,108.73,0,0,0,46.84,12.56l1.9.07V350.85a2.51,2.51,0,0,1,5,0v15.41l1.9-.07a108.1,108.1,0,0,0,46.79-12.56l1.68-.89L288,339.51a2.43,2.43,0,0,1,.86-3.41,5.3,5.3,0,0,1,1.34-.21,2.16,2.16,0,0,1,2.07,1.08L300,350.21l1.6-1a109.74,109.74,0,0,0,34.22-34.3l1-1.61-13.23-7.65a2.36,2.36,0,0,1-1.16-1.43,2.58,2.58,0,0,1,.24-2,2.43,2.43,0,0,1,2.12-1.18l12.9,7,1.66.95.89-1.67a109.25,109.25,0,0,0,12.56-46.87l.08-1.9H337.53a2.48,2.48,0,0,1-2.54-2.41,2.52,2.52,0,0,1,2.54-2.54h15.35l-.08-1.91a108.72,108.72,0,0,0-12.55-46.84l-.9-1.69-13.49,7.91-.93,0a2.39,2.39,0,0,1-2.25-1.12,2.62,2.62,0,0,1-.24-2,2.2,2.2,0,0,1,1.12-1.4l13.26-7.66-1-1.62A112.22,112.22,0,0,0,301.59,163L300,162l-7.72,13.38a2.54,2.54,0,0,1-2.13,1.14c-1.55-.47-2.21-1-2.41-1.76a2.45,2.45,0,0,1,.26-1.9l7.65-13.39-1.69-.88A109.79,109.79,0,0,0,247.15,146l-1.9-.07v15.42a2.51,2.51,0,0,1-2.55,2.54,2.48,2.48,0,0,1-2.47-2.54V145.93l-1.91.07a110.33,110.33,0,0,0-46.82,12.57l-1.7.88,7.71,13.37a2.7,2.7,0,0,1-.92,3.4l-.19.1-1,.06a2.5,2.5,0,0,1-2.32-1.11L185.53,162l-1.62,1a112.41,112.41,0,0,0-34.28,34.27l-1,1.63,13.32,7.64a2.4,2.4,0,0,1,1.14,1.49,2.48,2.48,0,0,1-2.41,3.19l-12.83-7.08-1.67-1-.9,1.7a108.44,108.44,0,0,0-12.55,46.83l-.07,1.91H148a2.48,2.48,0,0,1,0,4.95H132.62l.07,1.91a109,109,0,0,0,12.56,46.86l.89,1.67,13.29-7.64a5,5,0,0,1,1.27-.18,2.27,2.27,0,0,1,2.15,1.07,2.66,2.66,0,0,1-.92,3.37l-13.33,7.66,1,1.62a109.82,109.82,0,0,0,34.29,34.29l1.61,1L193.17,337a2.53,2.53,0,0,1,2.15-1.27c1.4.54,2.08,1.17,2.35,2a2.37,2.37,0,0,1-.19,1.9l-7.69,13.18Z'
    />
</svg>
);

const businessIcon = (
<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0)'>
    <path
        d='M17.5 11.3623L28.5125 13.5498C29.4 13.7373 30 14.4873 30 15.3748V28.1248C30 29.1623 29.1625 29.9998 28.125 29.9998H16.875C17.225 29.9998 17.5 29.7248 17.5 29.3748V28.7498H28.125C28.4625 28.7498 28.75 28.4748 28.75 28.1248V15.3748C28.75 15.0873 28.55 14.8248 28.2625 14.7623L17.5 12.6373V11.3623Z'
        fill='#23324E'
    />
    <path
        d='M24.375 17.5C24.725 17.5 25 17.775 25 18.125C25 18.475 24.725 18.75 24.375 18.75H21.875C21.525 18.75 21.25 18.475 21.25 18.125C21.25 17.775 21.525 17.5 21.875 17.5H24.375Z'
        fill='#23324E'
    />
    <path
        d='M24.375 21.25C24.725 21.25 25 21.525 25 21.875C25 22.225 24.725 22.5 24.375 22.5H21.875C21.525 22.5 21.25 22.225 21.25 21.875C21.25 21.525 21.525 21.25 21.875 21.25H24.375Z'
        fill='#23324E'
    />
    <path d='M24.375 25C24.725 25 25 25.275 25 25.625C25 25.975 24.725 26.25 24.375 26.25H21.875C21.525 26.25 21.25 25.975 21.25 25.625C21.25 25.275 21.525 25 21.875 25H24.375Z' fill='#23324E' />
    <path
        d='M17.5 29.3748C17.5 29.7248 17.225 29.9998 16.875 29.9998C16.525 29.9998 16.25 29.7248 16.25 29.3748V28.7498V11.8748C16.25 11.6873 16.3375 11.5123 16.475 11.3873C16.625 11.2748 16.8125 11.2248 17 11.2623L17.5 11.3623V12.6373V28.7498V29.3748Z'
        fill='#23324E'
    />
    <path d='M16.25 28.75V29.375C16.25 29.725 16.525 30 16.875 30H11.875C12.225 30 12.5 29.725 12.5 29.375V28.75H16.25Z' fill='#23324E' />
    <path d='M13.125 6.25C13.475 6.25 13.75 6.525 13.75 6.875C13.75 7.225 13.475 7.5 13.125 7.5H10.625C10.275 7.5 10 7.225 10 6.875C10 6.525 10.275 6.25 10.625 6.25H13.125Z' fill='#23324E' />
    <path d='M13.75 10.625C13.75 10.975 13.475 11.25 13.125 11.25H10.625C10.275 11.25 10 10.975 10 10.625C10 10.275 10.275 10 10.625 10H13.125C13.475 10 13.75 10.275 13.75 10.625Z' fill='#23324E' />
    <path d='M13.125 13.75C13.475 13.75 13.75 14.025 13.75 14.375C13.75 14.725 13.475 15 13.125 15H10.625C10.275 15 10 14.725 10 14.375C10 14.025 10.275 13.75 10.625 13.75H13.125Z' fill='#23324E' />
    <path
        d='M13.125 17.5C13.475 17.5 13.75 17.775 13.75 18.125C13.75 18.475 13.475 18.75 13.125 18.75H10.625C10.275 18.75 10 18.475 10 18.125C10 17.775 10.275 17.5 10.625 17.5H13.125Z'
        fill='#23324E'
    />
    <path d='M7.5 18.125C7.5 18.475 7.225 18.75 6.875 18.75H4.375C4.025 18.75 3.75 18.475 3.75 18.125C3.75 17.775 4.025 17.5 4.375 17.5H6.875C7.225 17.5 7.5 17.775 7.5 18.125Z' fill='#23324E' />
    <path d='M6.875 6.25C7.225 6.25 7.5 6.525 7.5 6.875C7.5 7.225 7.225 7.5 6.875 7.5H4.375C4.025 7.5 3.75 7.225 3.75 6.875C3.75 6.525 4.025 6.25 4.375 6.25H6.875Z' fill='#23324E' />
    <path d='M6.875 10C7.225 10 7.5 10.275 7.5 10.625C7.5 10.975 7.225 11.25 6.875 11.25H4.375C4.025 11.25 3.75 10.975 3.75 10.625C3.75 10.275 4.025 10 4.375 10H6.875Z' fill='#23324E' />
    <path d='M6.875 13.75C7.225 13.75 7.5 14.025 7.5 14.375C7.5 14.725 7.225 15 6.875 15H4.375C4.025 15 3.75 14.725 3.75 14.375C3.75 14.025 4.025 13.75 4.375 13.75H6.875Z' fill='#23324E' />
    <path
        d='M11.25 23.125C11.25 22.775 10.9625 22.5 10.625 22.5H6.875C6.525 22.5 6.25 22.775 6.25 23.125V28.75H5V23.125C5 22.0875 5.8375 21.25 6.875 21.25H10.625C11.6625 21.25 12.5 22.0875 12.5 23.125V28.75H11.25V23.125Z'
        fill='#23324E'
    />
    <path d='M6.25 28.75H11.25H12.5V29.375C12.5 29.725 12.225 30 11.875 30H5.625C5.275 30 5 29.725 5 29.375V28.75H6.25Z' fill='#23324E' />
    <path
        d='M2.1875 0.249922L15.925 2.33742C16.8375 2.48742 17.5 3.26242 17.5 4.18742V11.3624L17 11.2624C16.8125 11.2249 16.625 11.2749 16.475 11.3874C16.3375 11.5124 16.25 11.6874 16.25 11.8749V4.18742C16.25 3.87492 16.025 3.61242 15.725 3.56242L1.9875 1.48742C1.95 1.47492 1.9125 1.47492 1.875 1.47492C1.725 1.47492 1.5875 1.52492 1.475 1.62492C1.325 1.74992 1.25 1.91242 1.25 2.09992V28.1249C1.25 28.4749 1.5375 28.7499 1.875 28.7499H5V29.3749C5 29.7249 5.275 29.9999 5.625 29.9999H1.875C0.8375 29.9999 0 29.1624 0 28.1249V2.09992C0 1.54992 0.2375 1.02492 0.6625 0.674922C1.0875 0.312422 1.6375 0.162422 2.1875 0.249922Z'
        fill='#23324E'
    />
    </g>
    <defs>
    <clipPath id='clip0'>
        <rect width='30' height='30' fill='white' />
    </clipPath>
    </defs>
</svg>
);

const clipIcon = (
<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='45' height='45' viewBox='0 0 500 500'>
    <path
    className='cls-1'
    d='M142.65,380.7a53.07,53.07,0,0,1-37.45-90.49L199,196.43a52.92,52.92,0,0,1,90.4,37.44,53,53,0,0,1-15.44,37.61L251,294.4a2.91,2.91,0,0,1-2,.81,2.78,2.78,0,0,1-2-.81,2.92,2.92,0,0,1,0-4l22.92-22.92a47.37,47.37,0,1,0-67-67l-93.78,93.8a47.35,47.35,0,0,0,67,67l38.56-38.55a2.81,2.81,0,0,1,2-.81,3,3,0,0,1,2.07.84,3,3,0,0,1,0,4l-38.55,38.55A53,53,0,0,1,142.65,380.7Z'
    />
    <path
    className='cls-1'
    d='M142.65,380.55a52.92,52.92,0,0,1-37.34-90.24l93.77-93.77a52.77,52.77,0,0,1,90.15,37.33,52.9,52.9,0,0,1-15.39,37.51L250.91,294.3a2.78,2.78,0,0,1-1.92.76,2.66,2.66,0,0,1-1.89-.76,2.79,2.79,0,0,1,0-3.83L270,267.54a47.51,47.51,0,1,0-67.2-67.19l-93.78,93.79a47.5,47.5,0,0,0,67.18,67.18l38.57-38.55a2.64,2.64,0,0,1,1.88-.76,2.82,2.82,0,0,1,2,.79,2.8,2.8,0,0,1,0,3.8l-38.55,38.55A52.76,52.76,0,0,1,142.65,380.55Z'
    />
    <path
    className='cls-1'
    d='M269.56,325a52.37,52.37,0,0,1-37.45-15.42,53.26,53.26,0,0,1,0-75L255,211.61a2.95,2.95,0,0,1,4.07,0,2.93,2.93,0,0,1,0,4l-23,22.91a47.58,47.58,0,0,0,0,67,47.66,47.66,0,0,0,67,0l93.88-93.78a47.39,47.39,0,0,0-67.06-67l-38.48,38.55a2.85,2.85,0,0,1-4-4l38.57-38.57a53,53,0,0,1,75,75l-93.8,93.8A52.66,52.66,0,0,1,269.56,325Z'
    />
    <path
    className='cls-1'
    d='M269.56,324.86a52.22,52.22,0,0,1-37.35-15.38,53.11,53.11,0,0,1,0-74.84l22.93-22.92a2.78,2.78,0,0,1,3.85,0,2.62,2.62,0,0,1,.74,1.88,2.72,2.72,0,0,1-.77,1.92l-23,22.9a47.73,47.73,0,0,0,0,67.2,47.8,47.8,0,0,0,67.18,0L397,211.87a47.54,47.54,0,0,0-67.27-67.19l-38.48,38.55a2.7,2.7,0,0,1-3.82-3.81L326,140.85a52.88,52.88,0,1,1,74.75,74.83L307,309.48A52.51,52.51,0,0,1,269.56,324.86Z'
    />
    <path
    className='cls-1'
    d='M124.86,203.09a2.11,2.11,0,0,1-.89-.21l-.09-.21,0,.14a2.78,2.78,0,0,1,1-5.37,2.81,2.81,0,0,1,1.08.21,2.85,2.85,0,0,1,1.54,1.55,2.78,2.78,0,0,1,0,2.12A2.9,2.9,0,0,1,124.86,203.09Z'
    />
    <path className='cls-1' d='M124.86,202.94a2,2,0,0,1-.83-.2l-.07,0-.08,0a2.63,2.63,0,0,1,1-5.08,2.69,2.69,0,0,1,1,.2,2.69,2.69,0,0,1,1.48,3.47A2.75,2.75,0,0,1,124.86,202.94Z' />
    <path
    className='cls-1'
    d='M154.77,158.13a2.78,2.78,0,0,1-2-.84,2.88,2.88,0,0,1-.82-2,2.85,2.85,0,0,1,.81-2,2.93,2.93,0,0,1,2-.81,2.82,2.82,0,0,1,2,.81,2.77,2.77,0,0,1,0,4l-.07.08A2.52,2.52,0,0,1,154.77,158.13Z'
    />
    <path
    className='cls-1'
    d='M154.77,158a2.66,2.66,0,0,1-1.89-.79,2.78,2.78,0,0,1,0-3.83,2.81,2.81,0,0,1,1.95-.76,2.64,2.64,0,0,1,1.88.76,2.62,2.62,0,0,1,0,3.78l-.08.08A2.37,2.37,0,0,1,154.77,158Z'
    />
    <path
    className='cls-1'
    d='M200.1,384a2,2,0,0,1-.85-.21l-.09-.21,0,.14a2.81,2.81,0,0,1-1.55-1.55,2.64,2.64,0,0,1,0-2.08,2.79,2.79,0,0,1,2.56-1.75,2.62,2.62,0,0,1,1.06.22,2.72,2.72,0,0,1,1.55,1.5A2.84,2.84,0,0,1,200.1,384Z'
    />
    <path className='cls-1' d='M200.1,383.82a1.83,1.83,0,0,1-.78-.19l-.08,0-.08,0a2.67,2.67,0,0,1-1.46-1.47,2.51,2.51,0,0,1,0-2,2.66,2.66,0,0,1,4.9,0,2.69,2.69,0,0,1-2.51,3.74Z' />
    <path className='cls-1' d='M114.51,256.09a2.79,2.79,0,1,1,2.78-2.71A2.75,2.75,0,0,1,114.51,256.09Z' />
    <path className='cls-1' d='M114.51,255.94a2.64,2.64,0,1,1,2.63-2.56A2.61,2.61,0,0,1,114.51,255.94Z' />
    <path
    className='cls-1'
    d='M305.58,127.61a6.16,6.16,0,0,1-1.14-.18A2.86,2.86,0,0,1,303,123.8a2.82,2.82,0,0,1,2.63-1.84,2.73,2.73,0,0,1,1.05.21,2.91,2.91,0,0,1,1.56,3.68A3,3,0,0,1,305.58,127.61Z'
    />
    <path className='cls-1' d='M305.58,127.46a6.45,6.45,0,0,1-1.1-.17,2.73,2.73,0,0,1-1.35-3.43,2.68,2.68,0,0,1,2.5-1.75,2.61,2.61,0,0,1,1,.2,2.75,2.75,0,0,1,1.48,3.48A2.8,2.8,0,0,1,305.58,127.46Z' />
    <path
    className='cls-1'
    d='M199.66,127.92a3.08,3.08,0,0,1-2.68-1.69,3.17,3.17,0,0,1,.08-2.33,2.25,2.25,0,0,1,1.32-1.23l.21.07-.06-.14a3,3,0,0,1,1.23-.27,2.67,2.67,0,0,1,2.49,1.63,3,3,0,0,1-1.64,3.85A2.33,2.33,0,0,1,199.66,127.92Z'
    />
    <path
    className='cls-1'
    d='M199.66,127.77a2.91,2.91,0,0,1-2.55-1.61,3,3,0,0,1,.09-2.2,2.11,2.11,0,0,1,1.23-1.15l.08,0,.08,0a2.79,2.79,0,0,1,1.17-.26,2.52,2.52,0,0,1,2.35,1.54,2.86,2.86,0,0,1-1.56,3.65A2.19,2.19,0,0,1,199.66,127.77Z'
    />
    <path className='cls-1' d='M252.34,117.21a2.59,2.59,0,0,1-2.55-2.73,2.77,2.77,0,0,1,2.79-2.87,2.8,2.8,0,1,1,0,5.59Z' />
    <path className='cls-1' d='M252.34,117.06a2.44,2.44,0,0,1-2.4-2.58,2.62,2.62,0,0,1,2.64-2.72,2.65,2.65,0,1,1,0,5.29Z' />
    <path className='cls-1' d='M391.56,255.79a2.76,2.76,0,0,1-2.79-2.71v-.6a2.73,2.73,0,0,1,2.71-2.86,2.79,2.79,0,0,1,2.86,2.71v.75A2.75,2.75,0,0,1,391.56,255.79Z' />
    <path className='cls-1' d='M391.56,255.64a2.6,2.6,0,0,1-2.64-2.56v-.6a2.58,2.58,0,0,1,2.56-2.71,2.64,2.64,0,0,1,2.71,2.56v.75A2.6,2.6,0,0,1,391.56,255.64Z' />
    <path
    className='cls-1'
    d='M381.05,308.79a3,3,0,0,1-1.06-.26l-.12,0a2.32,2.32,0,0,1-1.35-1.26,3.14,3.14,0,0,1,0-2.43,3.09,3.09,0,0,1,2.69-1.64,2.48,2.48,0,0,1,.93.18,2.87,2.87,0,0,1,1.53,3.7A2.55,2.55,0,0,1,381.05,308.79Z'
    />
    <path
    className='cls-1'
    d='M381.05,308.64a2.7,2.7,0,0,1-1-.25l-.12-.05a2.17,2.17,0,0,1-1.27-1.18,3,3,0,0,1,0-2.32,2.94,2.94,0,0,1,2.55-1.54,2.32,2.32,0,0,1,.87.17,2.71,2.71,0,0,1,1.45,3.5A2.41,2.41,0,0,1,381.05,308.64Z'
    />
    <path
    className='cls-1'
    d='M351.05,353.59a2.33,2.33,0,0,1-1.95-.64l-.08-.08a2.67,2.67,0,0,1-.84-2,2.38,2.38,0,0,1,.77-1.77h.23l-.11-.1a2.61,2.61,0,0,1,1.95-.88A2.55,2.55,0,0,1,353,349l.1.09a2.47,2.47,0,0,1,.76,1.81,2.82,2.82,0,0,1-.8,2A2.35,2.35,0,0,1,351.05,353.59Z'
    />
    <path
    className='cls-1'
    d='M351.05,353.44a2.23,2.23,0,0,1-1.84-.59l-.09-.08a2.59,2.59,0,0,1-.79-1.91,2.19,2.19,0,0,1,.72-1.65l.07-.06.06-.07a2.5,2.5,0,0,1,1.84-.83,2.43,2.43,0,0,1,1.82.81l.1.1a2.28,2.28,0,0,1,.72,1.7,2.63,2.63,0,0,1-.76,1.92A2.16,2.16,0,0,1,351.05,353.44Z'
    />
    <path
    className='cls-1'
    d='M350.61,157.54a2.46,2.46,0,0,1-1.87-.78l-.08-.09a2.78,2.78,0,0,1-.85-2,2.31,2.31,0,0,1,.78-1.75l.22,0-.11-.1a2.58,2.58,0,0,1,1.92-.88,2.55,2.55,0,0,1,1.89.86l.07.07.07.06a2.4,2.4,0,0,1,.83,1.77,2.69,2.69,0,0,1-.82,2l-.07.08A2.62,2.62,0,0,1,350.61,157.54Z'
    />
    <path
    className='cls-1'
    d='M350.61,157.39a2.31,2.31,0,0,1-1.76-.73l-.08-.1a2.59,2.59,0,0,1-.81-1.91,2.16,2.16,0,0,1,.73-1.65l.06-.06.06-.07a2.47,2.47,0,0,1,1.81-.83,2.41,2.41,0,0,1,1.78.81l.07.07.08.07a2.26,2.26,0,0,1,.78,1.67,2.56,2.56,0,0,1-.77,1.9l-.08.07A2.45,2.45,0,0,1,350.61,157.39Z'
    />
    <path
    className='cls-1'
    d='M380.84,202.34a2.89,2.89,0,0,1-2.59-4,2.6,2.6,0,0,1,1.47-1.44,3,3,0,0,1,1.13-.23,2.89,2.89,0,0,1,2.44,1.34l.09.32.05.1a2.63,2.63,0,0,1,0,2.11,2.7,2.7,0,0,1-1.51,1.49A2.5,2.5,0,0,1,380.84,202.34Z'
    />
    <path
    className='cls-1'
    d='M380.84,202.19a2.72,2.72,0,0,1-2.45-3.78,2.47,2.47,0,0,1,1.38-1.35,2.78,2.78,0,0,1,1.08-.22,2.73,2.73,0,0,1,2.31,1.27l.08.28.06.12a2.54,2.54,0,0,1-1.4,3.4A2.31,2.31,0,0,1,380.84,202.19Z'
    />
    <path
    className='cls-1'
    d='M306.17,383.82A3,3,0,0,1,303.5,382a2.84,2.84,0,0,1,0-2.16,2.78,2.78,0,0,1,1.51-1.5,3,3,0,0,1,1.09-.21,2.8,2.8,0,0,1,2.62,1.7,2.76,2.76,0,0,1-1.54,3.65l-.22-.06.06.13A2.14,2.14,0,0,1,306.17,383.82Z'
    />
    <path
    className='cls-1'
    d='M306.17,383.67a2.85,2.85,0,0,1-2.53-1.69,2.7,2.7,0,0,1,0-2.05,2.62,2.62,0,0,1,1.44-1.41,2.72,2.72,0,0,1,3.51,1.41,2.59,2.59,0,0,1-1.46,3.45l-.08,0-.08,0A2,2,0,0,1,306.17,383.67Z'
    />
    <path
    className='cls-1'
    d='M125.07,309.08a2.85,2.85,0,0,1-2.59-1.79,2.79,2.79,0,0,1,0-2.14,2.68,2.68,0,0,1,1.47-1.5,2.92,2.92,0,0,1,3.78,1.52,2.81,2.81,0,0,1,0,2.13,2.71,2.71,0,0,1-1.52,1.49A2.5,2.5,0,0,1,125.07,309.08Z'
    />
    <path
    className='cls-1'
    d='M125.07,308.93a2.69,2.69,0,0,1-2.45-1.7,2.67,2.67,0,0,1,0-2,2.57,2.57,0,0,1,1.4-1.42,2.77,2.77,0,0,1,3.59,1.44,2.7,2.7,0,0,1-.05,2,2.51,2.51,0,0,1-1.43,1.41A2.32,2.32,0,0,1,125.07,308.93Z'
    />
    <path className='cls-1' d='M253.18,394.39a2.87,2.87,0,0,1-2.86-2.87,3,3,0,0,1,2.86-2.86,2.9,2.9,0,0,1,2.79,2.86A2.83,2.83,0,0,1,253.18,394.39Z' />
    <path className='cls-1' d='M253.19,394.24a2.72,2.72,0,0,1-2.72-2.72,2.86,2.86,0,0,1,2.72-2.71,2.74,2.74,0,0,1,2.63,2.71A2.68,2.68,0,0,1,253.19,394.24Z' />
    <path
    className='cls-1'
    d='M155.23,353.9a2.65,2.65,0,0,1-2-.85,2.69,2.69,0,0,1-.8-1.95,2.72,2.72,0,0,1,.8-1.94,2.8,2.8,0,0,1,2-.81,3,3,0,0,1,2.08.84,2.74,2.74,0,0,1,0,3.86A2.77,2.77,0,0,1,155.23,353.9Z'
    />
    <path className='cls-1' d='M155.23,353.75a2.51,2.51,0,0,1-1.93-.81,2.59,2.59,0,0,1,0-3.67,2.65,2.65,0,0,1,1.88-.77,2.84,2.84,0,0,1,2,.8,2.65,2.65,0,0,1-1.93,4.45Z' />
</svg>
);
  
const playIcon = (
<svg width='45' height='45' viewBox='0 0 45 45' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
    d='M18.077 28.6952H16.7054C16.58 28.6952 16.4597 28.6454 16.371 28.5567C16.2823 28.468 16.2324 28.3477 16.2324 28.2222C16.2324 28.0968 16.2823 27.9765 16.371 27.8878C16.4597 27.7991 16.58 27.7493 16.7054 27.7493H18.077C18.2025 27.7493 18.3228 27.7991 18.4115 27.8878C18.5002 27.9765 18.55 28.0968 18.55 28.2222C18.55 28.3477 18.5002 28.468 18.4115 28.5567C18.3228 28.6454 18.2025 28.6952 18.077 28.6952Z'
    fill='#23324E'
    />
    <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M26.8944 6.30469H6.2431C4.83863 6.30619 3.49211 6.86478 2.499 7.85789C1.50589 8.851 0.947303 10.1975 0.945801 11.602V23.3979C0.947303 24.8024 1.50589 26.1489 2.499 27.142C2.51211 27.1551 2.52528 27.1682 2.53851 27.1811L3.25977 26.5629C3.22866 26.5336 3.19793 26.5037 3.16761 26.4734C2.35185 25.6577 1.893 24.5516 1.89175 23.3979V11.602C1.893 10.4483 2.35185 9.34226 3.16761 8.5265C3.98338 7.71073 5.08943 7.25189 6.2431 7.25063H25.7908L26.8944 6.30469ZM31.2336 8.0267L31.968 7.39193C32.1547 7.53443 32.3328 7.68995 32.5007 7.85789C33.4938 8.851 34.0524 10.1975 34.0539 11.602V23.3979C34.0524 24.8024 33.4938 26.1489 32.5007 27.142C31.5076 28.1351 30.1611 28.6937 28.7566 28.6952H26.1032C25.9778 28.6952 25.8575 28.6454 25.7688 28.5567C25.6801 28.468 25.6303 28.3477 25.6303 28.2223C25.6303 28.0968 25.6801 27.9765 25.7688 27.8878C25.8575 27.7991 25.9778 27.7493 26.1032 27.7493H28.7566C29.9103 27.748 31.0163 27.2892 31.8321 26.4734C32.6479 25.6577 33.1067 24.5516 33.108 23.3979V11.602C33.1067 10.4483 32.6479 9.34226 31.8321 8.5265C31.6467 8.34106 31.4462 8.17407 31.2336 8.0267ZM8.41733 27.7493L7.323 28.6952H13.3944C13.5199 28.6952 13.6402 28.6454 13.7289 28.5567C13.8176 28.468 13.8674 28.3477 13.8674 28.2223C13.8674 28.0968 13.8176 27.9765 13.7289 27.8878C13.6402 27.7991 13.5199 27.7493 13.3944 27.7493H8.41733Z'
    fill='#23324E'
    />
    <path d='M34 3L0 32' stroke='black' />
    <path
    d='M22.7925 28.6952H21.3878C21.2623 28.6952 21.142 28.6454 21.0533 28.5567C20.9646 28.468 20.9148 28.3477 20.9148 28.2222C20.9148 28.0968 20.9646 27.9765 21.0533 27.8878C21.142 27.7991 21.2623 27.7493 21.3878 27.7493H22.7925C22.9179 27.7493 23.0382 27.7991 23.1269 27.8878C23.2156 27.9765 23.2655 28.0968 23.2655 28.2222C23.2655 28.3477 23.2156 28.468 23.1269 28.5567C23.0382 28.6454 22.9179 28.6952 22.7925 28.6952Z'
    fill='#23324E'
    />
    <path
    d='M15.2592 22.6696C14.7923 22.6691 14.3447 22.4834 14.0146 22.1533C13.6845 21.8232 13.4988 21.3756 13.4983 20.9087V14.0913C13.4982 13.7637 13.5896 13.4426 13.7622 13.1642C13.9348 12.8858 14.1818 12.6611 14.4752 12.5155C14.7687 12.3699 15.097 12.3092 15.4231 12.3402C15.7492 12.3711 16.0602 12.4926 16.321 12.6908L20.8076 16.0996C21.0235 16.2636 21.1986 16.4754 21.3191 16.7184C21.4396 16.9613 21.5023 17.2288 21.5023 17.5C21.5023 17.7712 21.4396 18.0387 21.3191 18.2817C21.1986 18.5246 21.0235 18.7364 20.8076 18.9005L16.3215 22.3092C16.0164 22.5424 15.6432 22.6691 15.2592 22.6696ZM15.2592 13.2759C15.1325 13.2762 15.0076 13.306 14.8945 13.3629C14.7577 13.4288 14.6426 13.5323 14.5626 13.6613C14.4826 13.7903 14.441 13.9395 14.4428 14.0913V20.9087C14.4425 21.0606 14.4846 21.2095 14.5645 21.3386C14.6445 21.4677 14.7589 21.5719 14.895 21.6393C15.0311 21.7066 15.1833 21.7346 15.3344 21.7199C15.4855 21.7052 15.6296 21.6485 15.7501 21.5562L20.2358 18.147C20.3357 18.0714 20.4168 17.9736 20.4726 17.8613C20.5284 17.749 20.5574 17.6254 20.5574 17.5C20.5574 17.3747 20.5284 17.251 20.4726 17.1387C20.4168 17.0265 20.3357 16.9287 20.2358 16.853L15.7501 13.4438C15.6099 13.3354 15.4378 13.2764 15.2606 13.2759H15.2592Z'
    fill='#23324E'
    />
</svg>
);
*/

const LinkSubmitPageNew = () => {
  const [loading, setIsLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [tzTime, setTzTime] = useState({ sf: "", ny: "", br: "" });
  const [state, dispatch] = useContext(Context);

  const checkEnabled = (cutoff_datetime, now, paymentEnabled, post_status) => {
    // Disabled: accepting_for_day day is equal to today's day AND current time is greater than cutoff_datetime_utc
    // Disabled: accepting_for_day day is not equal to today's day
    // Enabled: accepting_for_day day is equal to today's day AND current time is less than cutoff_datetime_utc
    // Disable form input if the user is not paying
    // Disable if user has not completed all assignments or in cooling period
    if (post_status !== "unlocked") {
      setIsEnabled(false);
      return;
    }
    if (paymentEnabled === false) {
      if (isEnabled === true) {
        setIsEnabled(false);
      }
      return;
    }
    console.log("cutoff time: ", cutoff_datetime, "current time: ", now);
    if (cutoff_datetime > now && cutoff_datetime.getTime() - now.getTime() < 60 * 60 * 1000) {
      setIsEnabled(true);
      return;
    } else {
      setIsEnabled(false);
      if (cutoff_datetime.getUTCDate() !== now.getUTCDate()) {
        // Delete saved link if past
        // If there is data in the currentSubmittedLink state clear it
        dispatch({ ...state.currentSubmittedLink.data, dateSubmitted: "", link: "", pod: "" });
        // Clear localstorage link
        deleteLinkDataLocalStorage();
        deleteGeneratedResponsesLocalStorage();
      }
      return;
    }
  };

  const displayNotification = () => {
    Notification.requestPermission()
      .then(() => {
        return getToken(messaging, {
          vapidKey: "BDy8ZpjLtHfj_63yJpUzDqPTew0XalbONBmFGGmZ0GFS17mQldOAFjJ6WBpDTCYImIQRZFpaD13I6dBBVzEdSMY",
        });
      })
      .then((token) => {
        console.log("firebase token: ", token);
        SaveDeviceService(token, "link-submission-reminder").then((res) => {
          if (res.status === 200) {
            alert("You've allowed notifications.");
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <div style={{ flex: "1" }}>
          {loading ? <Spinner className="spinner-container" /> : ""}
          <div className={`${styles["background"]}`}>
            <div className={`${styles["main"]} mt-5 `}>
              <div className="row  justify-content-between ">
                <div>
                  <p className={`${styles["title"]} h4 mb-5`}>Submit a link</p>
                </div>
              </div>
              <div className="row">
                <div className={`${styles["dot"]} d-flex align-content-center flex-wrap justify-content-center`}>
                  {timeIcon}
                </div>
                <div className="ml-4">
                  <div>
                    <p className={`${styles["title"]} h4 `}>Time till next submission</p>
                  </div>
                  <CutoffTimeComponent
                    checkEnabled={checkEnabled}
                    isEnabled={isEnabled}
                    setTzTime={setTzTime}
                    setIsLoading={setIsLoading}
                  />
                  <div className={`${styles["notification"]}`} onClick={displayNotification}>
                    <p>
                      <span role="img" aria-label="finger-pointing-right">
                        &#128073;
                      </span>{" "}
                      <b>Schedule 5-Minute Reminder:</b> Submit and fuel your LinkedIn post with engagement on time.
                      <span role="img" aria-label="finger-pointing-left">
                        &#128072;
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="ml-4 mt-4">
                <LinkSubmitForm isEnabled={isEnabled} />
              </div>
              <div>
                <div className={`${styles["container-vertial-box"]} ml-4`}>
                  <div className="ml-4">
                    <div className={`${styles["box-text-title"]} mt-3 mb-4`}>Rules for the post</div>
                    <ol className={`${styles["body-text"]} ml-4`}>
                      <li className="mb-4">Submitted posts must belong to you or your company profile</li>
                      <li className="mb-4">
                        No external links in the post (YouTube videos, LinkedIn Pulse Articles, etc)
                      </li>
                      <li className="mb-4">No old content. Post URL must be submitted within one hour of posting</li>
                      <li className="mb-4">
                        <div>Submit your post URL before the cutoff time below</div>
                        <div className={`${styles["row"]}`}>
                          <div className="flex-nowrap mb-2">
                            <div className="time-span">New York</div>
                            {/* <span className=""> →</span> */}
                            <div className="time-span">Sun, Tue & Thu</div>
                            <div className="list-inline-item time-span">{tzTime.ny} or UTC -5</div>
                          </div>
                          <div className="flex-nowrap">
                            <div className="time-span">San Francisco</div>
                            {/* <span className="list-inline-item">→</span> */}
                            <div className="time-span">Sun, Tue & Thu</div>
                            <div className="flex-nowrap list-inline-item time-span">{tzTime.sf} or UTC -8</div>
                          </div>
                          <div className="flex-nowrap">
                            <div className="time-span">Brisbane</div>
                            {/* <span className="list-inline-item">→</span> */}
                            <div className="time-span">Mon, Wed & Fri</div>
                            <div className="list-inline-item time-span mb-2">{tzTime.br} or UTC +10</div>
                          </div>
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkSubmitPageNew;
