import domain from "./DomainService";

/**
 * Returns a Promise
 *
 * @param {string} link The user's input url
 * @return {Promise}
 */
export default function SubmitLinkService(link) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ link: link, pod_id: "TIC1" }),
  };
  return fetch(domain() + "submit-link", options);
}

export function SubmitProspectsLinkService (link) {
  const token = localStorage.getItem("token");
  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "JWT " + token,
    },
    body: JSON.stringify({ link: link}),
  };
  return fetch(domain() + "submit-prospects-link", options);
}

